.faqSlider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .arrowButton {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    margin: 0 10px;
    color: aliceblue;
  }
  