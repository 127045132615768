.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 95vh;
    width: 100%;
}

.mainSection {
    background-color: white;
    border-radius: 5px;
    padding: 30px;
    box-shadow: var(--shadow);
}

.setTimerText {
    font-weight: 600;
    font-size: 24px;
}

.input {
    border: 1px var(--muted) solid;
    padding: 10px 15px;
    border-radius: 5px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin: 10px 0px 0px 0px;
}

.muted {
    font-size: 15px;
    color: var(--muted);
    margin-bottom: 2px;
}