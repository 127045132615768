.whiteboards_grid_container {
  margin: 20px;
  min-height: 75vh;
}

.whiteboards_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
}

.whiteboard_card {
  background-color: #f8f9fa; 
  border: 1px solid #e0e0e0; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  border-radius: 10px;
  padding: 16px;
  transition: box-shadow 0.3s ease-in-out;
  height: fit-content;
}

.whiteboard_card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); 
}
.card_title {
  font-size: 19px;
  margin-bottom: 5px;
  flex-grow: 1;
  font-weight: 500;
}

.createWhiteboardButton {
  background-color: var(--whiteboards-color);
  color: white;
  padding: 15px 15px;
  border: transparent;
  border-radius: 5px;
  margin-top: 20px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.nothing {
  font-size: 24px;
  font-weight: 600;
}