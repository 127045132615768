.mainContent {
    display: flex;
    flex-direction: column;
    min-height: 95vh;
    width: 100%;
}

.heroImage {
    object-fit: cover;
    position: absolute;
    height: 95%;
    width: 100%;
}

.heroSection {
    height: 95vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1;
}

.mainText {
    font-size: 128px;
    font-weight: 900;
    color: white;
    text-shadow: 0px 0px 23.6px rgba(255, 255, 255, 0.243);
}

.reviseText {
    color: white;
    font-size: 36px;
    font-weight: 500;
    margin: 10px 0px;
}

.signUpButton {
    padding: 20px 30px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: var(--shadow);
    margin-top: 20px;
    font-weight: 500;
}

.filledStar {
    color: #ffc300;
}

.starsSection {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #061125;
    border-bottom: 2px #ffc300 solid;
    border-top: 2px #eff2f1 solid;

}
.starCard {
    display: flex;
    flex-direction: column;    align-items: center;
    justify-content: center;
    gap: 20px;
    color: white;
}

.aboutSynapse {
    background-color: #eff2f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.aboutText {
    font-weight: 800;
    font-size:42px;
    margin: 30px;
}

.contents {
    display: flex;
    flex-direction: column;
}

.synapseDescription {
    margin-bottom: 30px;
}

.image {
    width: 50px;
    height: 50px;
    display: inline;
    margin-right: 20px;
}

.item {
    display: inline-flex;
    align-items: center;
    width: 50%;
}

.row {
    display: inline-block;
    margin: 40px 0px;
}


.faqTitle {
    color: white;
    font-size: 32px;
    font-weight: 600;
}
.faqSection {
    background-color: #061125;
    border-radius: 5px;
    box-shadow: var(--lesser-shadow);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}
@media (max-width: 768px) {
    .mainText {
        font-size: 60px;
        text-shadow: 0px 0px 23.6px rgba(56, 56, 56, 0.63);
    }
    .reviseText {
        font-size: 20px;
        text-align: center;
        text-shadow: 0px 0px 23.6px rgba(56, 56, 56, 0.63);
    }
}