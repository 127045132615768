.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 95vh;
}

.mainContent {
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: var(--shadow);
}

.mainText {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
}

.button {
    display: inline-block;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin-right: 10px;
    background-color: var(--muted);
    color: white;
}

.tag {
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: var(--lesser-shadow);
    height: max-content;
    padding: 10px;
    margin: 10px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tagName {
    font-weight: 500;
    font-size: 18px;
}

.view {
    background-color: var(--secondary);
    color: black;
}
.postButton {
    padding: 10px 15px;
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
    box-shadow: var(--lesser-shadow);
    width: fit-content;
    color: white;
}

@media (max-width: 768px) {
    .mainContent {
        margin: 20px 0px;
    }
    .button {
        width: 50%;
        font-size: 16px;
    }

    .buttonContainer {
        display: flex;
        margin-top: 5px;
    }

    .tagName {
        font-size: 19px;
    }

    .tag {
        flex-direction: column;
    }
}