.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 95vh;
    width: 100vw;
}

.mainContent {
    width: 80%;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    border: none;
    box-shadow: var(--shadow);
}

.postFlashcards {
    font-size: 32px;
    margin: 30px 0px 20px 0px;
    font-weight: 500;
}

.postFlashcardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mutedText {
    color: var(--muted);
}

.titleInput {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 20px 15px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.button {
    background-color: var(--muted);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    box-shadow: var(--lesser-shadow);
    margin-top: 20px;
}

.createTodoList {
    background-color: var(--primary);
}

.addTodoInput {
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    box-shadow: var(--lesser-shadow);
    width: 90%;
}

.addButton {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: var(--muted);
    color: white;
    margin-left: 20px;
    width: fit-content;
}

.pair {
    display: flex;
    width: 100%;
}

.todo {
    width: 100%;
    padding: 10px 15px;
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: var(--lesser-shadow);
    margin: 0px 0px 10px 0px;
}