
.card {
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: transparent;
    border-radius: 5px;
    margin: 20px auto;
    width: auto;
    height: auto;
    box-shadow: var(--shadow);
    background-color: white;
}

.title {
    font-size: 1.25em;
    margin-bottom: 15px;
}

.timerDisplay {
    font-size: 48px; /* Large text for the timer display */
    font-weight: bold;
    margin-bottom: 15px;
}

.buttons {
    display: flex;
    gap: 10px;
}

.button {
    border: transparent;
    border-radius: 5px;
    padding: 10px 15px;
}


.loadingIcon {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #838383;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s var(--spinner-bezier) infinite;
    margin: 30px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}