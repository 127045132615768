.mainContainer {
    width: 100vw;
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.postFlashcards {
    font-size: 32px;
    margin: 30px 0px 20px 0px;
    font-weight: 500;
}

.postFlashcardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.mutedText {
    color: var(--muted);
    text-align: center;
}

.learnLink {
    color: var(--primary);
}

.mainContent {
    padding: 30px;
    margin: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: var(--shadow);
    width: 50%;
    height: fit-content;
    background-color: white;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
}

.input {
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    box-shadow: var(--lesser-shadow);
    width: 100%;
    margin: 0px 10px 0px 0px;
}

.addTodoButton {
    background-color: var(--primary);
    width: fit-content;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    text-wrap: nowrap;
}

.addTodoSection {
    display: flex;
    width: 100%;
}

.allTodos {
    position: absolute;
    top: 6vh;
    left: 10px;
    background-color: var(--muted);
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    box-shadow: var(--lesser-shadow);
    width: fit-content;
    color: white;
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 30px;
    }
    
    .allTodos {
        top: 10vh;
    left: 10px;
    }

    .mainContent {
        width: 100%;
    }
}