.cards_grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 75vh;
}
.card {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}
.card_title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  flex-grow: 1;
}
.card_content {
  font-size: 1rem;
  color: #666;
  flex-basis: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.category_section {
  margin-bottom: 20px;
}
.category_title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}
.category_separator {
  height: 2px;
  background-color: #e0e0e0;
  margin-bottom: 20px;
}
.cards_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}