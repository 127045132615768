.container {
  max-width: 600px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: var(--shadow);
}

.todoItem:not(:last-child) {
  margin-bottom: 15px;
}

.todoText {
  margin: 0;
  flex-grow: 1;
}

.addTodo {
  background-color: var(--muted);
  width: 95px;
  border: none;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.button:hover {
  background-color: var(--color-primary-dark);
}


.todoInput {
  border-radius: 5px;
  border: none;
  background-color: var(--muted-background);
  margin-bottom: 10px;
  padding: 10px 15px;
  width: 70%;
}

.addTodoSection {
  width: 100%;
  display: flex;
  gap: 10px;
}

.todoListTitle {
  font-size: 17px;
  margin-bottom: 5px;
  text-align: left;
}

.todoNameBold {
  font-weight: 600;
}