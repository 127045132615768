.navbar {
    background-color: var(--navbar-color);
    padding: 1.5rem 1rem;
    padding: 1.5rem 0 1.5rem 1rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 12.3px rgba(0, 0, 0, 0.166);
    height: 5vh;
}

body {
    background-color: #fefefe;
}

.navbar_logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.navbar_logo a {
    display: flex;
    align-items: center;
}

.navbar_logo img {
    height: 40px;
}

.navbar_links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
}

.navbar_links li {
    margin-right: 1rem;
    flex: 0 1 auto;
}

.navbar_links li a {
    transition: color 0.3s ease;
    padding: 0.5rem;
    white-space: nowrap;
}

.dropdown {
    position: absolute;
    top: 56px;
    width: 200px;
    background-color: white;
    border: transparent;
    border-radius: 5px;
    padding: 10px;
    box-shadow: var(--lesser-shadow);
    z-index: 5;
}

.dropdownTab {
    margin: 10px 5px 10px 5px;
    font-weight: 500;
}

.profilePic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
    user-select: none;
    -webkit-user-drag: none;
}

.profileIcon {
    margin-left: 10px;
}


.navbar_logo img {
    margin-right: 0.5rem;
}

.navbar_logo span {
    margin-left: 0.5rem;
    font-weight: 900;
    font-size: 20px;
}

.myStuffButton, .postButton, .loginButton, .registerButton {
    font-weight: 600;
    font-size: 16px;
}

.postButton, .loginButton {
    background-color: var(--primary);
    height: 38px;
    width: 95px;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    transition: var(--transition025);
}

.postButton:hover, .loginButton:hover {
    background-color: var(--primary-hover);
}

.registerButton {
    background-color: var(--primary-variant);
    height: 38px;
    width: 95px;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition025);
}

.registerButton:hover {
    background-color: var(--primary-variant-hover);
}

@media (max-width: 768px) {
    .navbar-links li {
        margin-right: 0.5rem;
    }

    .navbar_logo img {
        height: 30px;
    }
    .navbar img {
        display: none;
    }

    .profileIcon {
        display: none;
    }
}