.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 95vh;
}

.mainContent {
    padding: 30px;
    margin: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: var(--shadow);
    width: 80%;
    background-color: white;
}
.postFlashcards {
    font-size: 32px;
    margin: 30px 0px 20px 0px;
    font-weight: 500;
}

.postFlashcardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input {
    width: 50%;
    padding: 15px;
    border: none;
    box-shadow: var(--lesser-shadow);
    border-radius: 5px;
}
.inputGroup {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.exam {
    background-color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    box-shadow: var(--lesser-shadow);
    display: flex;
    justify-content: space-between;   
    align-items: center;
    margin: 10px 0px 10px 0px;
}

.button { 
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: var(--muted);
    margin: 0px 5px 0px 5px;
    color: white;
}

.updateAdd {
    background-color: var(--primary);
    margin-top: 10px;
}