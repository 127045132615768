.mainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainContainerFinished {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.answerInput {
    background-color: var(--tertiary);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 10px;
}

.submitButton {
    padding: 10px 15px;
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
    box-shadow: var(--lesser-shadow);
    color: white;
}

.finishedText {
    color: black;
    font-weight: 600;
    font-size: 24px;
}

.flashcard {
    background-color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 15px;
    width: calc(976px * 0.8);
    height: calc(572px * 0.8);
    border: none;
    border-radius: 5px;
    box-shadow: var(--shadow);
}

.afterButton {
    background-color: var(--tertiary);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    width: 100px;
    margin-right: 10px;
}

.restartButton {
    background-color: var(--primary);
    color: white;
}

.mutedText {
    color: var(--muted);
}

.learnLink {
    color: var(--primary);
}

@media (max-width:768px) {
    .mainContainer {
        display: none;
    }
}