.card-container {
  width: 300px;  /* same width as your card */
  height: 200px; /* same height as your card */
  perspective: 1000px; /* required for the flip effect */
  margin: 10px; /* Adjust margin as needed */
}

.card {
  width: 100%;
  height: 100%;
  position: relative; /* Changed from absolute to relative */
  transition: transform 0.6s; /* change to adjust the flip speed */
  transform-style: preserve-3d; /* This is important for flipping */
}

.card .front,
.card .back {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden; /* hide the back side during the flip */
  position: absolute; /* this is important to stack the front and back */
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--lesser-shadow);
  font-size: 19px;
  padding: 20px
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}


.card .back {
  transform: rotateY(180deg); /* this flips the back side */
}

.flipped {
  transform: rotateY(180deg); /* this flips the whole card */
}
