.container {
    padding: 50px 0px 50px 0px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pageTitle {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 20px;
}

.tabs {
    gap: 10px;
}

.tab {
    background-color: var(--light-button);
    border: transparent;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-weight: 500;
    margin-right: 10px;
}

.tab:hover {
    background-color: var(--light-button-hover);
}

.flashcardsActive {
    background-color: var(--flashcards-color);
    color: white;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);

}

.flashcardsActive:hover {
    background-color: var(--flashcards-color-hover);
    color: white;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);

}

.notesActive {
    background-color: var(--notes-color);
    color: white;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);

}
.notesActive:hover {
    background-color: var(--notes-color-hover);
    color: white;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);

}

.whiteboardsActive {
    background-color: var(--whiteboards-color);
    color: white;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);

}

.whiteboardsActive:hover {
    background-color: var(--whiteboards-color-hover);
    color: white;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);

}

.searchInput {
    border: transparent;
    border-radius: 5px;
    padding: 15px;
    box-shadow: var(--lesser-shadow);
}

.postButton {
    padding: 10px 15px;
    background-color: var(--primary);
    color: white;
    border: transparent;
    border-radius: 5px;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow);
    font-size: 20px;
    font-weight: 600;
    text-shadow: 0px 0px 23.6px rgba(255, 255, 255, 0.311);
    position: fixed;
    width: 150px;
    height:60px;
    top: 85%;
    left: 85%;

}
@media (max-width: 768px) {
    .pageTitle {
        text-align: center;
    }
    .searchWrapper {
        display: none;
    }

    .container {
        padding: 20px
    }


    .tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .tab {
        font-size: 16px;
        margin-right: 0;
    }

    .postButton {
        display: none;
    }
}
