.mainContainer {
    width: 100vw;
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.mainContent {
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: var(--shadow);
    width: 50%;
    padding: 30px;
}

.postFlashcards {
    font-size: 32px;
    margin: 30px 0px 20px 0px;
    font-weight: 500;
}

.postFlashcardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.todoList {
    display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--muted-background);
  margin-bottom: 10px;
  padding: 20px 15px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 18px;
  font-weight: 500;
  box-shadow: var(--lesser-shadow);
}

.postButton {
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    background-color: var(--primary);
    color: white;
    width: fit-content;
    box-shadow: var(--lesser-shadow);

}

.mutedText {
    color: var(--muted);
    font-size: 15px;
    margin-bottom: 5px;
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 20px;
    }


    .postFlashcardsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mutedText {
        text-align: center;
    }

    .mainContent {
        width: 100%;
    }
}