.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 95vh;
  padding: 20px 0px;
}

.endContainer {
  width: 100%;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.finished {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 30px;
}

.mainSection {
  display: flex;
  flex-direction: row;
  width: 85%;
  justify-content: space-between;
  align-items: center;
}

.arrowButton {
  height: 40px;
  width: 40px;
  border: transparent;
  border-radius: 5px;
}

.dummy {
  width: 40px;
}

.bottomSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toggleButton {
  width: fit-content;
  height: fit-content;
  display: block;
  margin: 5px 0px 5px 0px;
  border: transparent;
  border-radius: 5px;
  padding: 15px 10px;
}

.progressText,
.endText {
  color: var(--muted);
}

.flashcard {
  width: 1000px;
  height: 60vh;
  border-radius: 5px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 19px;
  background-color: white;
  z-index: 0;
  background-image: linear-gradient(90deg, rgb(153, 0, 255) 0%, blue 100%);
  /* Fill the inside with white */
  background-origin: border-box;
  box-shadow: inset 0 100vw white, var(--lesser-shadow);
  /* A transparent border, so the very edge of the button shows through */
  border: 2px solid transparent;
  margin-bottom: 10px;
}


.endButton {
  color: white;
  background-color: var(--muted);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 10px;
  display: inline;
  transition: var(--transition025);

  &:hover {
    background-color: var(--muted-hover);
  }
}
.restartFlashcards {
    background-color: var(--primary);
}
.wellDone {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
}
@media (max-width: 768px) {
  .endContainer {
    background-color: transparent;
  }
  .flashcard {
    width: 100%;
    box-shadow: none;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .backEnd {
    position: absolute;
    top: 40vh;
    left: 10px;
  }

  .wellDone {
    font-weight: 600;
  }

  .endText {
    display: none;
  }
}
