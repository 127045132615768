/* Base layout styles */
.container {
  max-width: 80%;
  margin: 20px auto;
  padding: 20px;
  padding-right: 50px;
  background-color: #ffffff; 
  box-shadow: var(--lesser-shadow);
  border: none;
  border-radius: 5px;
}

.tag {
  background-color: var(--muted-background);
  padding: 5px 10px;
  border: transparent;
  border-radius: 5px;
  margin-right: 2px;
}

.title, .description, .owner, .tag, .link {
  color: #333; /* Darker text for better readability */
}

.title {
  font-size: 2rem; /* Larger size for titles */
  font-weight: 600; /* Semi-bold */
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  margin-bottom: 10px;
}

.ownerContainer, .tags {
  margin-bottom: 15px;
}

.owner, .tag {
  font-size: 0.85rem;
  font-style: normal; /* Regular style for owner names */
}


/* Buttons */
.interleavingButton {
  color: white;
  border: none;
  border-radius: 5px;
  background-color: var(--flashcards-color);
  padding: 10px 15px;
  font-weight: 500;
}

.tabButton {
  color: white;
  border: none;
  border-radius: 5px;
  background-color: var(--muted);
  padding: 10px 15px;
  font-weight: 500;
}

.editButton {
  color: var(--muted);
  border: none;
  border-radius: 5px;
  height: 36px;
  padding: 10px 15px;
  font-size: 1.2rem; /* Adjust size as needed */
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Layout adjustments */
.linksContainer {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  gap: 10px; /* Consistent gap between elements */
  align-items: center; /* This will vertically center the buttons */
  justify-content: space-between;
}

/* Flashcard carousel */
.flashcardCarousel {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out the navigation buttons */
  margin-bottom: 20px;
}

.flashcardCarousel button {
  border: none; /* Remove borders for a cleaner look */
  padding: 10px 15px; /* Generous padding for a larger click area */
  border-radius: 5px; /* Rounded corners for a modern look */
  font-size: 16px; /* Increase font size for better readability */
  cursor: pointer; /* Cursor indicates button is clickable */
}

.flashcard {
  box-shadow: var(--lesser-shadow);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

.flashcard h3 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.flashcard p {
  font-size: 1rem;
  line-height: 1.5;
}


.tagSection {
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
}

.linksContainerSecondary {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px 0px 10px 0px
}

.shareButton {
  color: var(--muted);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: 500;
  height: 36px;
  margin-left: 10px
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
  }

  .flashcardCarousel {
    display: none;
  }

  .flashcard {
    width: 100%;
  }
  .flashcard > p, .flashcard > h3 {
    width: 100%;
    word-wrap:break-word;
  }

  .tagSection {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .tabButton {
    width: 100% ;
  }

  .linksContainerSecondary {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin: 10px 0px 10px 0px
  }

}
