.todoItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--muted-background);
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.todoItem:hover {
  background-color: var(--tertiary);
}

.editText {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

.CheckboxInput {
  appearance: none;
    height: 37px;
  width: 37px;
  background-color: var(--tertiary);
  border: 1px #7272721a solid;
  border-radius: 5px;
  margin-right: 10px;
}

.CheckboxLabel {
  cursor: pointer;
}

.long {
  flex-direction: column;
  align-items: start;
}

.button {
  width: fit-content;
  padding: 10px;
  border: none;
  font-size: smaller;
  border-radius: 5px;
  color: white;
  text-wrap: nowrap;
}

.remove {
  background-color: var(--secondary);
}

.edit {
  background-color: var(--muted);
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.todoText {
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds an ellipsis at the end of the truncated text */
  max-width: 40%; /* You may need to adjust this value to fit the container's width */
}
.completed {
  text-decoration: line-through;
}
.completedInput {
  background-color: var(--muted);
  color: white;
}

.completedInput:hover {
  background-color: var(--muted-hover);
  color: white;
}