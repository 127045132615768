.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    min-height: 95vh;
}
.cards {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: left;
    gap: 100px; 
    padding-top: 100px;
    flex-wrap: wrap;
}
.title  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
}

.title > h3 {
    font-weight:600;
    font-size: 40px;
}

.title > p {
    margin-top: 30px;
    color: var(--muted);
}

.flashcard {
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: white;
    box-shadow: var(--shadow);
    color: black;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
}

.learnLink {
    color: var(--primary);
}

.importButton {
    background-color: var(--quizlet-color);
    color: white;
    font-weight: 500;
    padding: 15px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: var(--lesser-shadow);
}

@media (max-width: 768px) {
    .cards {
        gap: 20px;
        margin-top: 20px;
        padding-top: 20px;
    }
}