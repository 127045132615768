.card {
    text-align: center;
    background-color: white;
    border-radius: 10px; /* Rounded corners */
    box-shadow: var(--shadow); /* Subtle shadow */
    padding: 20px;
    position: relative; /* For positioning the streak counter and fire */
}

.streakDisplay {
    margin-top: 20px; /* Add space above the streak display */
}

.welcomeMessage {
    font-size: 1rem; /* Adjust font size as necessary */
    color: #707070; /* Muted text color */
}

.fireGif, .fireInactive {
    width: 100px; /* Size of the fire image/icon */
    height: auto;
    margin: 0 auto; /* Center the fire image/icon */
}

.fireInactive {
    color: #333;
}

.streakCountActive,
.streakCountInactive {
    position: absolute;
    top: 50%; /* Position at the vertical center of the parent */
    left: 50%; /* Position at the horizontal center of the parent */
    transform: translate(-50%, -50%); /* Center the element */
    font-size: 3rem; /* Large font size for the streak number */
    font-weight: bold; /* Make the streak number bold */
    color: #333; /* Dark color for visibility */
}

.button {
    background-color: #4CAF50; /* Green background for the button */
    color: white; /* White text on the button */
    padding: 15px 25px;
    border: none;
    border-radius: 5px; /* Slightly rounded corners for the button */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for the button */
    font-weight: bold; /* Make button text bold */
    text-transform: uppercase; /* Uppercase button text */
    margin-top: 30px; /* Space above the button */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.button:hover {
    background-color: #45a049; /* Darker green on hover */
}


.revised {
    box-shadow: 0px 0px 23.6px rgba(55, 205, 57, 0.45);
}

.streakDisplay {
    color: white;
}