.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    min-height: 95vh;
}

.flashcard {
    background-color: white;
    border: none;
    border-radius: 5px;
    padding: 30px;
    width: 80%;
    box-shadow: var(--shadow);
}

.mainText {
    font-size: 26px;
    font-weight: 600;
}

.title {
    font-weight: 600;
    font-size: 32px;
    border: none;
    margin: 10px 0px 10px 0px;
}

.sectionText {
    margin: 10px 0px 10px 0px;
    color: var(--text-muted-color);
}

.sectionTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0px 10px 0px;
}

.button {
    border: none;
    border-radius: 5px;
    box-shadow: var(--shadow);
    padding: 10px 15px;
    margin-right: 10px;
}

.addSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.addSectionButton {
    background-color: var(--secondary);
}

.deleteSectionButton {
    background-color: var(--muted);
    color: white;
}

.input {
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px var(--muted) solid;
    margin: 5px 10px 5px 0px;
}

.updateButton {
    background-color: var(--primary);
    color: white;
    margin-top: 10px;
}

.deleteButton {
    background-color: var(--danger);
    color: white;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .title {
        width: 100%;
    }

    .mainContainer {
        padding: 10px;
    }

    .flashcard {
        width: 90%;
        margin: 0;
    }

    .sectionTab {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        border: none;
        box-shadow: var(--lesser-shadow);
        margin-top: 10px;
        padding: 10px;
        gap: 10px;
    }

    .addSection {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .addSection > span> input {
        width: 100%;
    }

    .addSection > button {
        width: 100%;
        margin: 10px 0px;
    }
}