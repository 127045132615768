.mainContainer {
    width: 100vw;
    height: calc(100vh - 32px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.mainContent {
    padding: 30px;
    margin: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: var(--shadow);
    width: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.postFlashcards {
    font-size: 32px;
    margin: 30px 0px 20px 0px;
    font-weight: 500;
}

.postFlashcardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.input {
    display: inline-block;
    border: none;
    border-radius: 5px;
    box-shadow: var(--lesser-shadow);
    padding: 15px;
    width: 100%;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.button {
    width: max-content;
    box-shadow: var(--lesser-shadow);
    background-color: var(--primary);
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    margin: 10px 20px 10px 0px;
}

@media (max-width: 768px) {
    .mainContent {
        width: 80%;
    }
}