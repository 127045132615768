@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
    --navbar-color: #ffffff;
    --background-color: #ffffff;
    --footer-color: #303338;
    --muted-background: #F3F3F3; 
    --dots-color: #a8abae;
    
    --text-color: black;
    --text-muted-color:#414141;

    --primary: #3A86FF;
    --primary-hover: #2168d9;
    --secondary: #b9bbbe;
    --secondary-hover: #9d9fa4;
    --tertiary: #f1f1f1;
    --tertiary-hover: #c5c0c0;
    --success: #5DC133;
    --success-hover: #519d31;
    --danger: #FD2B3B;
    --danger-hover: #bd1d2a;
    --quizlet-color: #4255ff;
    --quizlet-color-hover: #2636c5;

    --flashcards-color: #8338ec;
    --flashcards-color-hover: #5b1bb4;
    --whiteboards-color: #e51022;
    --whiteboards-color-hover: #d51323;
    --notes-color: #fb5607;
    --notes-color-hover: #db5010;


    --primary-variant: #000080;
    --primary-variant-hover: #07075d;
    

    --muted: #797979;
    --muted-hover: #5f5a5a;

    --muted-link-color: #274c77;
    --muted-link-color-hover: #203955;

    --light-button: #e9e9e9;
    --light-button-hover: #d2d2d2;
    
    --font: "Inter", sans-serif; 
    
    --shadow: 0 0px 23.6px rgba(0, 0, 0, 0.25);
    --greater-shadow: 0 0px 23.6px rgba(0, 0, 0, 0.35);
    --lesser-shadow: 0 0px 10px rgba(0, 0, 0, 0.17);
    --transition1: 1s cubic-bezier(0.47, 0, 0.745, 0.715);
    --transition05: 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    --transition025: 0.25s cubic-bezier(0.47, 0, 0.745, 0.715);

    --spinner-bezier: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mainContainer {
    background-color: var(--background-color);
    
    background-size: 40px 40px;
    color: var(--text-color);
    height: fit-content;
    width: 100vw;
    font-family: var(--font);
}

@media (min-width: 768px) {.mainContainer {
    background-image: radial-gradient(var(--dots-color) 1px, transparent 0);
}}

