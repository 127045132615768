.mainText {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
}

.mainContainer {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

}

.mainContent {
    padding: 30px;
    margin: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: var(--shadow);
    width: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.muted {
    color: var(--text-muted-color);
    margin-bottom: 20px;
}