.mainContainer {
    width: 100vw;
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainContent {
    padding: 30px;
    margin: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: var(--shadow);
    width: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.postFlashcards {
    font-size: 36px;
    margin: 30px 0px 20px 0px;
    font-weight: 900;
}

.postFlashcardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mutedText {
    color: var(--muted);
}

.learnLink {
    color: var(--primary);
}

.input {
    width: 100%;
    padding: 15px;
    border: none;
    box-shadow: var(--lesser-shadow);
    border-radius: 5px;
}
.halfSize {
    width: 50%;
}

.inputGroup {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.button {
    width: max-content;
    box-shadow: var(--lesser-shadow);
    background-color: var(--primary);
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    margin-right: 20px;
    transition: var(--transition025);
}

.button:hover {
    background-color: var(--primary-hover);
}
.registerButton {
    background-color: var(--muted);
}

.registerButton:hover {
    background-color: var(--muted-hover);
}

.errorMessage {
    color: var(--danger);
    font-weight: 500;
}
/* helo */

@media (max-width: 768px) {
    .button {
        margin-bottom: 20px;
        width: 100%;
    }
}