.mainContainer {
    width: 100%;
    min-height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleText {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
}

.flashcard {
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: var(--shadow);
    width: 80%;
    padding: 30px;
    margin: 30px;
}

.presets {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.timerButton, .extraButtons, .createConfigButton {
    padding: 10px 15px;
    margin: 0px 10px 10px 0px;
    border-radius: 5px;
    border: none;
    box-shadow: var(--lesser-shadow);
    background-color: var(--secondary);
    
}

.timerSlot {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.timerName {
    font-weight: 500;
    font-size: 18px;
}

.setTimer {
    background-color: var(--muted);
    color: white;
}

.examText {
    font-weight: 500;
    font-size: 21px;
    margin-bottom: 20px;
}

.createConfigButton {
    background-color: var(--primary);
    color: white;
}

.examConfigs {
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .buttonContainer {
        height: 100%;
    }

    .extraButtons {
        display: inline-block;
    }

    .setTimer {
        display: none;
    }

    .timerName {
        height: fit-content;
        margin: 2px 0px;
    }

    .flashcard {
        margin: 10px;
        width: 90%;
        height: fit-content;
    }

    .timerButton {
        font-size: 14px;
        background-color: var(--muted);
        color: white;
    }
}