.mainContainer {
    width: 100vw;
    min-height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flashcard {
    border: none;
    border-radius: 5px;
    box-shadow: var(--shadow);
    background-color: white;
    padding: 50px;
    margin: 0px 5px;
}

@media (max-width: 768px) {
    .flashcard {
        background: none;
    }
}