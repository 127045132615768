.option {
    background-color: white;
    border: none;
    border-radius: 5px;
    padding: 15px;
    box-shadow: var(--lesser-shadow);
    width: 300px;
    text-align: left;
    margin: 10px;
}

.correct {
    background-color: #90ee90;
}

.incorrect {
    background-color: #ffcccb;
}

.nextButton {
    background-color: var(--tertiary);
    border: none;
    border-radius: 5px;
    padding: 10px 15px 10px 15px;
    box-shadow: var(--lesser-shadow);
    margin: 10px;
}

.flashcardQuestion {
    font-weight: 600;
    font-size: 20px;
    margin: 10px;
}

.flashcardContainer {
    min-height: 100%;

}

.writtenAnswer {
    background-color: var(--tertiary);
    border: none;
    border-radius: 5px;
    margin: 10px;
    padding: 10px 15px;
    width: 100%;
}

.submitButton {
    background-color: var(--muted);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    box-shadow: var(--lesser-shadow);
    margin: 10px;
}

.correctAnswer {
    margin: 10px;
}