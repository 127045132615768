.welcomeText {
  font-weight: 700;
  font-size: 64px;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: left;
}
.welcomeTextSubtitle {
  margin-top: 10px;
  margin-left: 5px;
  font-size: 26px;
  font-weight: 400;
  text-align: left;
}

.greetingSection {
  margin-top: 2em;
  margin-bottom: 3em;
}

.myStuffLink {
  background-color: var(--primary);
  height: 38px;
  width: 100%;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition025);
}

.myStuffLink:hover {
  background-color: var(--primary-hover);
}

.card {
  height: 150px;
  width: 243px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: var(--shadow);
  margin-bottom: 20px;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.dashboardContainer {
  width: 100%;
  min-height: 95vh;
}

.card:hover {
  box-shadow: var(--greater-shadow);
  transform: scale(1.01);
}

.card h3 {
  font-size: 17px;
  margin: 0;
}

.card p {
  margin-top: 4px;
  font-size: 0.9rem;
  color: #666;
}

/* Hello */

.cardLink {
  text-decoration: none;
  color: inherit;
  text-align: left;
  width: 100%;
}

.cardBody,
.cardContent {
  text-align: left;
  width: 100%;
}

.lastRevised {
  font-size: 0.8rem;
  color: #999;
  text-align: right;
  padding: 8px 16px;
  border-top: 1px solid #f0f0f0;
}

.gridContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 2em;
  flex-wrap: wrap;
}

.latestText {
  width: 100%;
  text-align: left;
  color: #676767;
  font-weight: 300;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.cardContent {
  font-size: 0.9rem;
  color: #666;
  margin-top: 4px;

}


.nothingSection {
  width: 100%;
  height: max-content;
  background-color: white;
  border: 1px solid rgb(189, 189, 189); 
  border-radius: 5px;
  box-shadow: inset 0px 0px 50px #3A86FF1f, var(--shadow);
  display: flex;
  justify-content: left;
  align-items: start;
  text-align: left;
  flex-direction: column;
  padding: 30px;
}

.nothingText {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
}

.tourContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  margin: 20px 0px 0px 0px;
}

.tourButton {
  width: fit-content;
  font-weight: 500;
  background-color: var(--success);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  box-shadow: var(--lesser-shadow);
}

.info {
  color: var(--text-muted-color);
  margin: 20px 0px;
}
@media screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .mainContent {
    align-items: center;
  }

  .gridContainer, .greetingSection, .welcomeText, .latestText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .welcomeText {
    font-size: 28px;
  }

  .card {
    margin-bottom: 5px;
  }
  .gridContainer {
    gap: 10px;
  }

  .myStuffLinkArrow {
    display: none;
  }
}