/* CompactTimerPage.module.css */
.container {
    max-width: max(300px, auto);
    margin: auto;
    background-color: white;
    padding: 20px;
    box-shadow: var(--shadow);
    border-radius: 5px;
    margin: 10px 0;
}

.header {
    text-align: center;
}


.button {
    background-color: var(--secondary);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
}

.input {
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0;
    width: 95%;
    background-color: var(--muted-background);
    border: transparent;
}

.label {
    display: block;
    margin: 10px 0;
}
