.mainContainer {
    height: 95vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.leftSection {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightSection {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10%;
}

.mainText {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .leftSection {
        display: none;
    }
}